import Script from 'next/script'

export default function FBSDK() {
    return (
        <>
            <Script
                id="fbscript"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
                    (function(d, s, id) {
                        var js, fjs = d.getElementsByTagName(s)[0];
                        if (d.getElementById(id)) return;
                        js = d.createElement(s); js.id = id;
                        js.src = "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.0";
                        fjs.parentNode.insertBefore(js, fjs);
                        }(document, 'script', 'facebook-jssdk'));;
                `,
                }}
            />
        </>
    )
}

